import { Patients } from "cadendar-shared";
import AptCaracsEdit from "./AptCaracsEdit.tsx";
import { useNewApt } from "./hooks/useNewApt.ts";
import { Transforms } from "cadendar-shared";
import { trpc } from "../../main/trpc.ts";

interface NewAptSecondScreenProps {
  hour: number;
  minute: number;
  date: Date;
  patient: Patients;
  onCancel: () => void;
  onSuccessfulSave: () => void;
  onSaveError: () => void;
  onPatientEditClick: (_id: string) => void;
}

const NewAptSecondScreen = (props: NewAptSecondScreenProps) => {
  const {
    tempRdv,
    onSetHM,
    onSetDuree,
    onSetType,
    onSetObjet,
    dureesPossibles,
    hmPossibles,
    onToggleReminder,
    onToggleConfirm,
  } = useNewApt(
    { hour: props.hour, minute: props.minute, date: props.date },
    props.date
  );

  const remindersCheckboxEnabled = Transforms.patients.hasRemindersTargets(
    props.patient
  );
  const confirmCheckboxEnabled = Transforms.patients.hasConfirmationTarget(
    props.patient
  );

  const trpcutils = trpc.useContext();

  const saveNewAptMutation = trpc.rdv.createMultiple.useMutation({
    onSuccess: () => {
      trpcutils.rdv.invalidate();
      props.onSuccessfulSave();
    },
    onError: props.onSaveError,
  });

  const handleSave = () => {
    saveNewAptMutation.mutate({
      rdvs: [{ ...tempRdv, patient_id: props.patient.id }],
      createReminders: tempRdv.reminderChecked,
      askForConfirmation: tempRdv.confirmChecked,
      mode: "manuel",
    });
  };
  return (
    <div>
      <AptCaracsEdit
        apt={{
          ...tempRdv,
          name: props.patient.name,
          forename: props.patient.forename,
        }}
        cancelEnabled={false}
        hmPossibles={hmPossibles}
        dureesPossibles={dureesPossibles}
        remindersCheckboxEnabled={remindersCheckboxEnabled}
        confirmCheckboxEnabled={confirmCheckboxEnabled}
        onSetDuree={onSetDuree}
        onSetType={onSetType}
        onSetObjet={onSetObjet}
        onToggleReminder={onToggleReminder}
        onToggleConfirm={onToggleConfirm}
        onSetHM={onSetHM}
        onCancel={() => {}}
        onCancelEdit={props.onCancel}
        onSave={handleSave}
        onEditPatient={() => props.onPatientEditClick(props.patient.id)}
      />
    </div>
  );
};

export default NewAptSecondScreen;
