import { AppointmentsId, HorairesDTO, Transforms } from "cadendar-shared";
import { usePreferences } from "../modules/preferences/hooks/usePreferences.ts";
import GrilleHeure from "./GrilleHeure.tsx";
import { DateTime } from "luxon";
import AgendaCase, { RdvCaseType } from "./AgendaCase.tsx";
import { css } from "../../styled-system/css";
import { useRef } from "react";
import { AppointmentsDTO } from "cadendar-trpc-server";

interface AgendaColumnProps {
  date: Date;
  onAptPress: (apt: AppointmentsDTO) => void;
  onEmptyCaseClick: (caseCaracs: {
    hour: number;
    minute: number;
    date: Date;
  }) => void;
  onAptArchive: (apt: { id: AppointmentsId }) => void;
  hmEdited: { hour: number; minute: number } | null;
  apts: AppointmentsDTO[];
  horaires: HorairesDTO;
}

const AgendaColumn = (props: AgendaColumnProps) => {
  const ref = useRef<HTMLDivElement | null>(null);
  const apts = props.apts;
  const horaires = props.horaires;
  const dayHoraires =
    horaires && Transforms.horaires.relevantHoraire([horaires], props.date);
  const horairesExtremes = (horaires &&
    Transforms.horaires.horairesExtremesForWeek(horaires)) || {
    start: { hour: 0, minute: 0 },
    end: { hour: 23, minute: 59 },
  };
  const débutHeureAffichage =
    Transforms.horaires.getDebutHeureAffichage(horairesExtremes);
  const finHeureAffichage =
    Transforms.horaires.getFinHeureAffichage(horairesExtremes);
  const prefs = usePreferences();
  const duréeMinRdv = prefs?.duree_min_rdv || 15;
  if (!dayHoraires) return null;
  const dayApts = apts.filter((apt) => {
    return apt.date.getTime() === props.date.getTime();
  });
  const dayToDisplay = Transforms.rdvs.dayClosedReplacer(
    Transforms.rdvs.getRdvsWithEmpties(
      Transforms.horaires.bornesForDay(horaires, new Date(props.date)),
      dayApts,
      duréeMinRdv,
      débutHeureAffichage,
      finHeureAffichage,
      new Date(props.date)
    ),
    new Date(props.date),
    débutHeureAffichage,
    finHeureAffichage
  );
  const dayIdx = DateTime.fromJSDate(props.date).weekday - 1;
  const handleAptPress = (apt: RdvCaseType) => {
    if (apt.tag === "rdv") {
      props.onAptPress(apt);
    }
    if (apt.tag === "empty") {
      props.onEmptyCaseClick(apt);
    }
  };
  return (
    <div
      className={css({
        display: "flex",
        flexDirection: "horizontal",
        marginBottom: 1,
        borderBottom: 1,
      })}
      ref={ref}
    >
      <GrilleHeure
        start={horairesExtremes.start.hour * 60 + horairesExtremes.start.minute}
        end={horairesExtremes.end.hour * 60 + horairesExtremes.end.minute}
        step={duréeMinRdv}
        aptMinDuration={duréeMinRdv}
        dayIdx={dayIdx}
      />
      <div className={css({ flexGrow: 1 })}>
        {dayToDisplay.map((apt) => {
          return (
            <AgendaCase
              appt={apt}
              onPress={handleAptPress}
              onCrossClick={props.onAptArchive}
              key={JSON.stringify(apt)}
              isAccentuated={
                props.hmEdited?.hour === apt.hour &&
                props.hmEdited?.minute === apt.minute
              }
            />
          );
        })}
      </div>
    </div>
  );
};

export default AgendaColumn;
