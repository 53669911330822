import _ from "underts";
import { Collections, HorairesDTO, Transforms } from "cadendar-shared";
import { useState } from "react";
import { AppointmentsDTO } from "cadendar-trpc-server";

const getHeuresPossibles = (
  rdvs: AppointmentsDTO[],
  dureeMiniRdv: number,
  date: Date,
  horaire: HorairesDTO,
  currentRdv?: AppointmentsDTO
) => {
  const bornes = Transforms.horaires.bornesForDay(horaire, date);
  const dayRdvs = rdvs.filter((rdv) => rdv.date.getDate() === date.getDate());
  const rdvsGroupedByPlage = bornes.map((plage: Collections.Plage) =>
    Transforms.rdvs.rdvsFromPlage(dayRdvs, plage)
  );
  const emptyCasesGroupedByPlage = rdvsGroupedByPlage.map((plageRdvs, index) =>
    Transforms.rdvs.emptyCases(
      plageRdvs,
      bornes[index],
      dureeMiniRdv,
      currentRdv
    )
  );
  return emptyCasesGroupedByPlage.flat(1);
};

const autoSetDuree = (actualDuree: number, dureesPossibles: number[]) => {
  if (dureesPossibles.includes(actualDuree)) {
    return actualDuree;
  } else {
    return _.last(dureesPossibles) || 15;
  }
};

interface AptTimeCaracs {
  hour: number;
  minute: number;
  duration: number;
  date: Date;
}

const useAptTimeCaracs = (
  apt: AptTimeCaracs,
  horaire: HorairesDTO,
  dayRdvs: AppointmentsDTO[],
  prefs: { duree_min_rdv: number }
) => {
  const initialDureesPossibles = Transforms.rdvs.getDurees2(
    horaire,
    dayRdvs,
    apt.date,
    {
      hour: apt.hour,
      minute: apt.minute,
    },
    prefs.duree_min_rdv
  );
  const [timedApt, setTimedApt] = useState({
    ...apt,
    duree: autoSetDuree(apt.duration, initialDureesPossibles),
  });
  const hmPossibles = apt.date
    ? getHeuresPossibles(dayRdvs, prefs.duree_min_rdv, apt.date, horaire)
    : [];
  const dureesPossibles = Transforms.rdvs.getDurees2(
    horaire,
    dayRdvs,
    timedApt.date,
    { hour: timedApt.hour, minute: timedApt.minute },
    prefs.duree_min_rdv
  );

  const onSetHM = (hm: { hour: number; minute: number }) => {
    setTimedApt((tempRdv) => ({
      ...tempRdv,
      hour: hm.hour,
      minute: hm.minute,
    }));
    const dureesPossibles = timedApt.date
      ? Transforms.rdvs.getDurees2(
          horaire,
          dayRdvs,
          timedApt.date,
          hm,
          prefs.duree_min_rdv
        )
      : [];
    //on modifie la durée déjà réglée si elle n'est plus possible
    setTimedApt((tempRdv) => {
      const _duree = autoSetDuree(tempRdv.duree, dureesPossibles);
      console.log("set autoSetDuree", _duree);
      return { ...tempRdv, duree: _duree };
    });
  };
  const onSetDuree = (duree: number) => {
    setTimedApt((tempRdv) => ({ ...tempRdv, duree }));
  };
  return {
    timedApt,
    hmPossibles,
    onSetHM,
    onSetDuree,
    dureesPossibles,
  };
};

export default useAptTimeCaracs;
