import { checkers, formaters } from "vincent-utils";
import _ from "underts";
import { useEffect, useState } from "react";
import {
  newPatientSchema,
  etatCivilStrings,
  Patients,
  updatePatientPropsSchema,
} from "cadendar-shared";
import { trpc } from "../../main/trpc.ts";
import EtatCivilField from "./EtatCivilField.tsx";
import style from "./PatientEdit.module.css";
import OkButton from "../buttons/OkButton.tsx";
import CancelButton from "../buttons/CancelButton.tsx";
import styles from "../apts/AptCaracsEdit.module.css";

export interface PatientEtatCivil {
  NAME: string;
  FORENAME: string;
  BIRTHDATE: string;
  PORTABLE: string;
  EMAIL: string;
  COMMENTAIRES: string;
}

type keys = keyof typeof etatCivilStrings;

const validate = (values: Record<keys, string>) => {
  const errors = {} as Record<keys, string>;
  if (!values.NAME) {
    errors.NAME = "obligatoire";
  } else if (!checkers.isNomOrPrenom(values.NAME)) {
    errors.NAME = "nom invalide";
  }
  if (!values.FORENAME) {
    errors.FORENAME = "obligatoire";
  } else if (!checkers.isNomOrPrenom(values.FORENAME)) {
    errors.FORENAME = "prénom invalide";
  }
  if (!values.BIRTHDATE) {
    errors.BIRTHDATE = "obligatoire";
  } else if (!checkers.isValidDdn(values.BIRTHDATE)) {
    errors.BIRTHDATE = "ddn invalide";
  }
  if (values.PORTABLEE164 && !checkers.isPortable(values.PORTABLEE164)) {
    errors.PORTABLEE164 = "numéro de portable invalide";
  }
  return errors;
};

const converters = {
  BIRTHDATE: function (ddnString: string) {
    "use strict";
    return formaters.formatDDNForStoring(ddnString);
  },
  NAME: function (nomString: string) {
    "use strict";
    return formaters.capitaliseEveryFirstLetter(nomString);
  },
  FORENAME: function (nomString: string) {
    "use strict";
    return formaters.capitaliseEveryFirstLetter(nomString);
  },
  PORTABLEE164: function (portable: string) {
    "use strict";
    return formaters.formatTelNumberForDisplay(portable);
  },
  EMAIL: (value: string) => value,
  COMMENTS: (value: string) => value,
};

const getPatientDTOFromTempInput = (tempInput: Record<keys, string>) => {
  return {
    name: tempInput.NAME.toLowerCase(),
    forename: tempInput.FORENAME.toLowerCase(),
    birthdate: formaters.formatDDNForStoring(tempInput.BIRTHDATE),
    portablee164:
      formaters.formatTelNumberForStoring(tempInput.PORTABLEE164) || "",
    email: tempInput.EMAIL || "",
    comments: tempInput.COMMENTS || "",
  };
};

const patientToTempInput = (
  patient: Partial<
    Pick<
      Patients,
      "name" | "forename" | "birthdate" | "portablee164" | "email" | "comments"
    >
  >
) => {
  return {
    NAME: (patient.name && converters.NAME(patient.name)) || "",
    FORENAME: (patient.forename && converters.FORENAME(patient.forename)) || "",
    BIRTHDATE:
      (patient.birthdate && converters.BIRTHDATE(patient.birthdate)) || "",
    PORTABLEE164:
      (patient.portablee164 && converters.PORTABLEE164(patient.portablee164)) ||
      "",
    EMAIL: (patient.email && converters.EMAIL(patient.email)) || "",
    COMMENTS: patient.comments || "",
  };
};

interface EditPatientProps {
  selectedPatientId: string | null;
  onSetSelectedPatientId: (id: string) => void;
  onClose: () => void;
}

interface PatientEditHeaderProps {
  onCancelEdit: () => void;
}

const PatientEditHeader = (props: PatientEditHeaderProps) => {
  return (
    <div className={styles.topBar}>
      <div>
        <div className={styles.spacer}>
          <div className={styles.patientName}>Etat civil patient</div>

          <span className={styles.circle} onClick={props.onCancelEdit}>
            <svg
              width="800px"
              height="800px"
              viewBox="0 0 19 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              className={styles.icon}
            >
              <path
                className={styles.icon}
                d="M6 6L18 18M18 6L6 18"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </span>
        </div>
      </div>
    </div>
  );
};

const EditPatient = (props: EditPatientProps) => {
  const [etatCivilMode, setEtatCivilMode] = useState<
    "viewPatient" | "editPatient" | "newPatient"
  >("editPatient");
  const isEditing =
    etatCivilMode === "editPatient" || etatCivilMode === "newPatient";
  const { data: selectedPatients } = trpc.patient.getByIds.useQuery(
    { _ids: [props.selectedPatientId as string] },
    { enabled: !!props.selectedPatientId }
  );

  const selectedPatient = _.first(selectedPatients || []);

  // const { tempPatient } = useTempPatient();
  // const _hasFamille = !!selectedPatient && !!selectedPatient.famille_id;

  const [tempInput, setTempInput] = useState(patientToTempInput({}));
  // useEffect(() => {
  //   setTempInput(patientToTempInput(tempPatient));
  // }, [JSON.stringify(tempPatient)]);
  useEffect(() => {
    if (!selectedPatient) return;
    setTempInput(patientToTempInput(selectedPatient));
  }, [JSON.stringify(selectedPatient)]);
  const initialTouchedState = Object.keys(etatCivilStrings).reduce(
    (result, key) => ({ ...result, [key]: false }),
    {} as { [K in keys]: boolean }
  );

  const [touchedState, setTouchedState] = useState(initialTouchedState);
  const utils = trpc.useContext();
  const commitNewPatientMutation = trpc.patient.create.useMutation({
    onSuccess: (value: string) => {
      setEtatCivilMode("viewPatient");
      utils.patient.invalidate();
      utils.rdv.invalidate(); //on invalide aussi les rdvs car si le nom du patient est modifié ça modifie le rdv
      props.onSetSelectedPatientId(value);
      setEtatCivilMode("viewPatient");
    },
  });
  const commitEditPatientMutation = trpc.patient.update.useMutation({
    onSuccess: () => {
      setEtatCivilMode("viewPatient");
      utils.patient.invalidate();
      utils.rdv.invalidate();
    },
  });
  const onSubmit = () => {
    if (etatCivilMode === "newPatient") {
      const newPatientDTO = {
        // ...tempPatient,
        ...getPatientDTOFromTempInput(tempInput),
      };
      commitNewPatientMutation.mutate(newPatientSchema.parse(newPatientDTO));

      return;
    }
    if (etatCivilMode === "editPatient") {
      if (!selectedPatient)
        throw new Error("selectedPatient is null in onSubmit");
      const patientDTO = {
        ...selectedPatient,
        ...getPatientDTOFromTempInput(tempInput),
      };

      commitEditPatientMutation.mutate({
        id: selectedPatient.id,
        props: updatePatientPropsSchema.parse(patientDTO),
      });
      setEtatCivilMode("viewPatient");
      return;
    }
  };
  // const onClickPatientEdit = () => setEtatCivilMode("editPatient");
  // const [, openFamilleGrid] = useAtom(FamilleGridOpenState);
  // const onClickOpenFamilleGrid = () => openFamilleGrid(true);

  const errors = validate(tempInput);

  const validatorMap = {
    NAME: (value: string) => {
      return checkers.isNomOrPrenom(value);
    },
    FORENAME: (value: string) => {
      return checkers.isNomOrPrenom(value);
    },
    BIRTHDATE: (value: string) => {
      return checkers.isValidDdn(value);
    },
    PORTABLEE164: (value: string) => {
      return !!checkers.isPortable(value);
    },
    EMAIL: (value: string) => {
      return !!checkers.isEmail(value);
    },
    COMMENTS: () => {
      return true;
    },
  };

  const newPatientFields = Object.keys(etatCivilStrings) as keys[];

  const handleTempInput = (key: keys, value: string) => {
    setTempInput((tempInput) => ({ ...tempInput, [key]: value }));
    setTouchedState((state) => ({ ...state, [key]: true }));
  };

  const handleBlur = (key: keys) => {
    const value = tempInput[key];
    if (validatorMap[key](value)) {
      const converted = converters[key](value) || "";
      setTempInput((state) => ({ ...state, [key]: converted }));
    }
  };
  return (
    <div className={style.main}>
      <PatientEditHeader onCancelEdit={props.onClose} />
      <div className={style.civilPanelSpacer}>
        <div className={style.civilPanel}>
          {newPatientFields.map((key) => (
            <EtatCivilField
              errors={errors[key]}
              label={etatCivilStrings[key]}
              id={key}
              name={key}
              value={tempInput[key] || ""}
              onChange={(value) => handleTempInput(key, value)}
              onBlur={() => {
                handleBlur(key);
              }}
              touched={touchedState[key]}
              isEditing={true}
              key={"EtatCivilFieldKey" + key}
              isValid={validatorMap[key](tempInput[key])}
            />
          ))}

          {/*{isEditing ? (
          ""
        ) : _hasFamille ? (
          <button
            type="button"
            id="open_famille_dialog"
            className="btn btn-info"
            onClick={onClickOpenFamilleGrid}
          >
            Editer Famille Existante
          </button>
        ) : (
          <button
            type="button"
            id="open_famille_dialog"
            className="btn btn-default"
            onClick={onClickOpenFamilleGrid}
          >
            Créer Famille
          </button>
        )}*/}
        </div>
        {isEditing ? (
          <div className={style.buttonGroup}>
            {Object.keys(errors).length === 0 ? (
              <OkButton onClick={onSubmit} label="Enregistrer" />
            ) : null}
            <CancelButton onClick={props.onClose} label="Abandonner" />
          </div>
        ) : (
          ""
        )}
      </div>
    </div>
  );
};

export default EditPatient;
