import { useEffect, useState } from "react";
import { useHoraires } from "../../horaires/hooks/useHoraires.ts";
import useApptsByDateWindow from "./useApptsByDateWindow.ts";
import { usePreferences } from "../../preferences/hooks/usePreferences.ts";
import { Transforms } from "cadendar-shared";

interface NewAptState {
  date: Date;
  hour: number;
  minute: number;
  duration: number;
  type: number;
  object: string;
  motif: number[];
  reminderChecked: boolean;
  confirmChecked: boolean;
}

interface NewAptProposedCaracs {
  hour: number;
  minute: number;
  date: Date;
}

function getInitialState(rdv: NewAptProposedCaracs, dureeMinRdv: number) {
  return {
    ...rdv,
    reminderChecked: false as boolean,
    confirmChecked: false as boolean,
    type: 1,
    object: "",
    duration: dureeMinRdv,
    motif: [] as number[],
  } satisfies NewAptState;
}

const useNewApt = (rdvState: NewAptProposedCaracs, date: Date) => {
  const prefs = usePreferences();
  const minDuree = prefs?.duree_min_rdv || 15;
  const [tempRdv, setTempRdv] = useState(getInitialState(rdvState, minDuree));

  useEffect(() => {
    if (!rdvState) return;
    setTempRdv(getInitialState(rdvState, minDuree));
  }, [JSON.stringify(rdvState)]);
  const horaire = useHoraires(tempRdv && tempRdv.date);
  const allRdvs = useApptsByDateWindow(date, date);

  const onSetHM = ({ hour, minute }: { hour: number; minute: number }) => {
    setTempRdv((tempRdv) => {
      const dureesPossibles =
        horaire &&
        prefs &&
        tempRdv &&
        Transforms.rdvs.getDurees2(
          horaire,
          allRdvs,
          tempRdv.date,
          { hour, minute },
          prefs.duree_min_rdv
        );
      const choosenDuree =
        (dureesPossibles &&
          (dureesPossibles.includes(tempRdv.duration)
            ? tempRdv.duration
            : dureesPossibles[0])) ||
        0;
      return { ...tempRdv, hour, minute, duree: choosenDuree };
    });
  };

  const onSetDuree = (duree: number) => {
    setTempRdv((tempRdv) => ({ ...tempRdv, duree }));
  };
  const onSetType = (type: number) => {
    setTempRdv((tempRdv) => ({ ...tempRdv, type }));
  };
  const onSetObjet = (object: string) => {
    setTempRdv((tempRdv) => ({ ...tempRdv, object }));
  };
  const onToggleReminder = () => {
    setTempRdv((tempRdv) => ({
      ...tempRdv,
      reminderChecked: !tempRdv.reminderChecked,
    }));
  };
  const onToggleConfirm = () => {
    setTempRdv((tempRdv) => ({
      ...tempRdv,
      confirmChecked: !tempRdv.confirmChecked,
    }));
  };
  const hmPossibles =
    (horaire &&
      prefs &&
      rdvState &&
      Transforms.rdvs.hmAvailableWithDuration(
        horaire,
        allRdvs,
        rdvState.date,
        prefs.duree_min_rdv,
        tempRdv.duration
      )) ||
    [];
  const dureesPossibles =
    (tempRdv &&
      prefs &&
      horaire &&
      Transforms.rdvs.getDurees2(
        horaire,
        allRdvs,
        date,
        { hour: tempRdv.hour, minute: tempRdv.minute },
        prefs.duree_min_rdv
      )) ||
    [];
  return {
    tempRdv,
    hmPossibles,
    dureesPossibles,
    onSetHM,
    onSetDuree,
    onSetType,
    onSetObjet,
    onToggleReminder,
    onToggleConfirm,
  };
};

export { useNewApt };
