import { CadendarConsts, Transforms } from "cadendar-shared";
import { useEditRdv as useEditApt } from "./hooks/useEditApt";
import { trpc } from "../../main/trpc.ts";
import _ from "underts";
import AptCaracsEdit from "./AptCaracsEdit.tsx";
import { AppointmentsDTO } from "cadendar-trpc-server";

interface AptEditProps {
  apt: AppointmentsDTO;
  date: Date;
  useEditApt: typeof useEditApt;
  onCancel: () => void;
  onSaveSuccess: () => void;
  onSaveError: () => void;
  onPatientEditClick: (_id: string) => void;
}

const AptEditScreen = (props: AptEditProps) => {
  const apt = props.apt;
  const useEditApt = props.useEditApt;
  const reminders = trpc.reminder.getRemindersByAptId.useQuery(apt.id).data
    ?.reminders;
  const {
    tempRdv,
    hmPossibles,
    dureesPossibles,
    onSetDuree,
    onSetHM,
    onSetObjet,
    onSetType,
    onToggleConfirm,
    onToggleReminder,
  } = useEditApt(apt, reminders || [], props.date);
  const { data: patient } = trpc.patient.getById.useQuery(
    apt.patient_id as string,
    { enabled: !!apt.patient_id }
  );
  const trpcUtils = trpc.useContext();
  const saveMutation = trpc.rdv.commitEdited.useMutation({
    onSuccess: () => {
      trpcUtils.rdv.invalidate();
      props.onCancel();
      props.onSaveSuccess();
    },
    onError: () => {
      props.onSaveError();
    },
  });
  const cancelMutation = trpc.rdv.autoDelete.useMutation({
    onSuccess: () => {
      trpcUtils.rdv.invalidate();
      props.onCancel();
      props.onSaveSuccess();
    },
    onError: () => {
      props.onSaveError();
    },
  });

  const handleSave = async () => {
    if (!tempRdv) return;
    const addReminders = tempRdv.reminderChecked;
    const addConfirmations = tempRdv.confirmChecked;
    const removeReminders = !tempRdv.reminderChecked;
    const removeConfirmations = !tempRdv.confirmChecked;
    saveMutation.mutate({
      rdv: _.omit(tempRdv, ["reminderChecked", "confirmChecked"]),
      addReminders,
      addConfirmations,
      removeReminders,
      removeConfirmations,
    });
  };
  const handleCancel = async () => {
    if (!tempRdv) return;
    const result = confirm(
      "Etes-vous sûr de vouloir supprimer ce rendez-vous?"
    );
    if (!result) return;
    cancelMutation.mutate({ _id: tempRdv.id });
  };
  console.log("tempRdv", tempRdv);
  const types = CadendarConsts.typeNums.map(
    CadendarConsts.getTypeNameFromTypeNumber
  );
  types.sort();

  const remindersCheckboxEnabled =
    !!patient && Transforms.patients.hasRemindersTargets(patient);
  const confirmCheckboxEnabled =
    !!patient && Transforms.patients.hasConfirmationTarget(patient);
  return tempRdv && hmPossibles ? (
    <AptCaracsEdit
      apt={tempRdv}
      hmPossibles={hmPossibles}
      dureesPossibles={dureesPossibles}
      remindersCheckboxEnabled={remindersCheckboxEnabled}
      confirmCheckboxEnabled={confirmCheckboxEnabled}
      onSetDuree={onSetDuree}
      onSetType={onSetType}
      onSetObjet={onSetObjet}
      onToggleReminder={onToggleReminder}
      onToggleConfirm={onToggleConfirm}
      onSetHM={onSetHM}
      onCancel={handleCancel}
      onCancelEdit={props.onCancel}
      onSave={handleSave}
      cancelEnabled={true}
      onEditPatient={() => {
        if (!patient) return;
        props.onPatientEditClick(patient.id);
      }}
    />
  ) : null;
};

AptEditScreen.defaultProps = {
  useEditApt: useEditApt,
};

export default AptEditScreen;
