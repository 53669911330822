import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { httpBatchLink, TRPCClientError, TRPCLink } from "@trpc/client";
import type { AppRouter } from "cadendar-trpc-server";
import superjson from "superjson";
import { useEffect } from "react";
import { observable } from "@trpc/server/observable";
import ScreenSwitcher from "./ScreenSwitcher.tsx";
import { trpc } from "./trpc.ts";
import { Doctors } from "cadendar-shared";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      throwOnError: (error) => {
        if (error instanceof TRPCClientError) {
          if (error.message === "UNAUTHORIZED") {
            window.location.reload();
          }
        }
        return false;
      },
    },
  },
});

const hostDirty = import.meta.env.VITE_DEV_HOST || window.location.host;
const clientBuildNumber = import.meta.env.VITE_BUILD_NUMBER;
const mode = import.meta.env.MODE;
const prefix = mode === "development" ? "http://" : "https://";
const host = hostDirty.replace("http://", "").replace("https://", "");
const url = `${prefix}${host}/cadendarApi`;

interface MainContainerProps {
  token: string;
  user: Doctors;
}

let token = "";
let owner = "";

function getBuildNumberFromResponse(response: Response) {
  const headers = response.headers;
  const build = headers.get("x-build-number");
  console.log("build", build);
  return build;
}

const customLink: TRPCLink<AppRouter> = () => {
  return ({ next, op }) => {
    // this is when passing the result to the next link
    // each link needs to return an observable which propagates results
    return observable((observer) => {
      return next(op).subscribe({
        next(value) {
          const response = value.context?.response as Response | undefined;
          const serverBuildNumber = response
            ? getBuildNumberFromResponse(response)
            : null;
          if (serverBuildNumber && serverBuildNumber !== clientBuildNumber) {
            console.log("force reload");
            console.log("serverBuildNumber", serverBuildNumber);
            console.log("clientBuildNumber", clientBuildNumber);
            location.reload();
          }
          response && getBuildNumberFromResponse(response);
          observer.next(value);
        },
        error(err) {
          observer.error(err);
        },
        complete() {
          observer.complete();
        },
      });
    });
  };
};

const trpcClient = trpc.createClient({
  links: [
    customLink,
    httpBatchLink({
      url,
      headers() {
        return {
          authorization: `Bearer ${token}`,
          "x-cadendar-owner": owner,
        };
      },
      fetch(url, options) {
        return fetch(url, {
          ...options,
          credentials: "include",
        });
      },
      transformer: superjson,
    }),
  ],
});

const MainContainer = (props: MainContainerProps) => {
  useEffect(() => {
    token = props.token;
  }, [props.token]);
  useEffect(() => {
    owner = props.user.id;
  }, [props.user]);
  return (
    <trpc.Provider client={trpcClient} queryClient={queryClient}>
      <QueryClientProvider client={queryClient}>
        <ScreenSwitcher />
      </QueryClientProvider>
    </trpc.Provider>
  );
};

export default MainContainer;
