import { css } from "../../../styled-system/css";
import Select from "../../baseComps/Select.tsx";
import { formLabelTextStyle } from "./styles.ts";
import { formaters } from "vincent-utils";
import { HorairesDTO } from "cadendar-shared";
import useAptTimeCaracs from "./hooks/useAptTimeCaracs.ts";
import { Button2 } from "../../baseComps/Button.tsx";
import { AppointmentsDTO } from "cadendar-trpc-server";

interface SpecialAptCaracsProps {
  hour: number;
  minute: number;
  date: Date;
  duration: number;
  horaire: HorairesDTO;
  dayRdvs: AppointmentsDTO[];
  prefs: { duree_min_rdv: number };
  onCommit: ({
    hour,
    minute,
    duration,
  }: {
    hour: number;
    minute: number;
    duration: number;
  }) => void;
}

const SpecialAptCaracs = (props: SpecialAptCaracsProps) => {
  const { timedApt, onSetDuree, onSetHM, hmPossibles, dureesPossibles } =
    useAptTimeCaracs(
      {
        hour: props.hour,
        minute: props.minute,
        date: props.date,
        duration: props.duration,
      },
      props.horaire,
      props.dayRdvs,
      props.prefs
    );
  const handleCommit = () => {
    props.onCommit({
      hour: timedApt.hour,
      minute: timedApt.minute,
      duration: timedApt.duration,
    });
  };
  return (
    <div>
      <div className={css({ display: "flex", marginY: 5 })}>
        <div className={css({ marginX: 10 })}>
          <div className={formLabelTextStyle()}>hour</div>
          <Select
            value={
              (timedApt &&
                formaters.formatHourMinuteObjectForDisplay({
                  hour: timedApt.hour,
                  minute: timedApt.minute,
                })) ||
              "0:00"
            }
            onChange={(event) => {
              const splitted = event.target.value.split(":");
              const hour = parseInt(splitted[0]);
              const minute = parseInt(splitted[1]);
              onSetHM({ hour, minute });
            }}
          >
            {hmPossibles &&
              hmPossibles.map((hm) => (
                <option
                  value={formaters.formatHourMinuteObjectForDisplay(hm)}
                  key={JSON.stringify(hm)}
                >
                  {formaters.formatHourMinuteObjectForDisplay(hm)}
                </option>
              ))}
          </Select>
        </div>
        <div>
          <div className={css({})}>
            <div className={formLabelTextStyle()}>Durée</div>
            <Select
              value={(timedApt && timedApt.duree) || 0}
              onChange={(event) => {
                onSetDuree(parseInt(event.target.value));
              }}
              className={css({
                backgroundColor: "white",
              })}
            >
              {dureesPossibles &&
                dureesPossibles.map((duree) => (
                  <option
                    value={duree}
                    key={duree}
                    className={css({
                      backgroundColor: "white",
                    })}
                  >
                    {duree}
                  </option>
                ))}
            </Select>
          </div>
        </div>
      </div>
      <div className={css({ display: "flex", justifyContent: "center" })}>
        <Button2 color="proceed" onClick={handleCommit}>
          Ok
        </Button2>
      </div>
    </div>
  );
};

export default SpecialAptCaracs;
