import { useEffect, useLayoutEffect, useRef } from "react";
import { DateTime } from "luxon";
import AgendaColumn from "./AgendaColumn.tsx";
import { css } from "../../styled-system/css";
import { AppointmentsId, HorairesDTO } from "cadendar-shared";
import _ from "underts";
import { AppointmentsDTO } from "cadendar-trpc-server";

interface AgendaColumnsSwiperProps {
  dateSelected: Date;
  apts: AppointmentsDTO[];
  hmSelected: { hour: number; minute: number } | null;
  horaires: HorairesDTO;
  onSetPreviousDay: () => void;
  onSetNextDay: () => void;
  onSelectApt: (apt: AppointmentsDTO) => void;
  onClickEmptyCase: (caseCaracs: {
    hour: number;
    minute: number;
    date: Date;
  }) => void;
  onArchiveApt: (apt: { id: AppointmentsId }) => void;
}

const AgendaColumnsSwiper = (props: AgendaColumnsSwiperProps) => {
  const dateSelected = props.dateSelected;
  const columnsRefs = useRef({} as { [key: string]: HTMLDivElement });
  const swiperRef = useRef<HTMLDivElement>(null);
  useLayoutEffect(() => {
    const targetRef = _.getSafeMapValue(
      columnsRefs.current,
      dateSelected.getTime()
    );
    if (!targetRef) {
      console.log(
        "targetRef not found for dateSelected",
        dateSelected.toLocaleDateString()
      );
    }

    const behavior = "auto";
    if (targetRef) {
      targetRef.scrollIntoView({ behavior });
    }
  }, [dateSelected]);
  useEffect(() => {
    const element = swiperRef.current;
    if (!element) return;

    const handleScrollEnd = () => {
      const element = swiperRef.current;
      if (!element) throw new Error("element is null");
      const index = Math.round(element.scrollLeft / window.innerWidth);
      if (index === 0) {
        props.onSetPreviousDay();
      }
      if (index === 2) {
        props.onSetNextDay();
      }
    };

    element.addEventListener("scrollend", handleScrollEnd);
    return () => {
      element.removeEventListener("scrollend", handleScrollEnd);
    };
  }, []);

  const datesNumbers = [
    DateTime.fromJSDate(dateSelected).minus({ day: 1 }).toMillis(),
    dateSelected.getTime(),
    DateTime.fromJSDate(dateSelected).plus({ day: 1 }).toMillis(),
  ];
  const slides = datesNumbers.map((dateNum) => (
    <div
      className={css({
        minWidth: "100%",
        width: "100%",
        scrollSnapAlign: "center",
      })}
      key={JSON.stringify(new Date(dateNum)) + "AgendaColumnKey"}
      ref={(ref) => {
        if (ref) {
          columnsRefs.current[dateNum] = ref;
        }
      }}
      id={new Date(dateNum).toISOString() + " AgendaColumnId"}
    >
      <AgendaColumn
        date={new Date(dateNum)}
        onAptPress={props.onSelectApt}
        onEmptyCaseClick={props.onClickEmptyCase}
        hmEdited={props.hmSelected}
        apts={props.apts}
        horaires={props.horaires}
        onAptArchive={props.onArchiveApt}
      />
    </div>
  ));
  return (
    <div
      className={css({
        overflow: "auto",
        scrollSnapType: "x mandatory",
        flexFlow: "row nowrap",
        display: "flex",
      })}
      id={"AgendaColumnsSwiper"}
      ref={swiperRef}
    >
      {slides}
    </div>
  );
};

export default AgendaColumnsSwiper;
