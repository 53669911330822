import { useEffect, useState } from "react";
import {
  getConnectedUser as _getConnectedUser,
  getToken as _getToken,
} from "./loginApi.ts";
import LoginScreenUI from "./LoginScreenUI.tsx";
import MainContainer from "../main/MainContainer.tsx";
import { Doctors } from "cadendar-shared";

interface LoginSwitcherProps {
  getToken: typeof _getToken;
  getConnectedUser: typeof _getConnectedUser;
}

const LoginSwitcher = (props: LoginSwitcherProps) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [token, setToken] = useState("");
  const [user, setUser] = useState<Doctors | null>(null);
  useEffect(() => {
    const token = localStorage.getItem("token");
    if (token) {
      setToken(token);
    }
  }, []);
  useEffect(() => {
    if (token) {
      props.getConnectedUser(token).then((user) => {
        setUser(user);
      });
    }
  }, [token]);
  const handleSubmit = async (obj: {
    emailOrUsername: string;
    password: string;
  }) => {
    setIsSubmitting(true);
    const { token: _token } = await props.getToken(
      obj.emailOrUsername,
      obj.password
    );
    setToken(_token);
    await localStorage.setItem("token", _token);
    const user = await props.getConnectedUser(_token);
    setUser(user);
    setIsSubmitting(false);
  };
  if (token && user) {
    return <MainContainer token={token} user={user} />;
  } else {
    return (
      <LoginScreenUI onSubmit={handleSubmit} submitDisabled={!isSubmitting} />
    );
  }
};

LoginSwitcher.defaultProps = {
  getToken: _getToken,
  getConnectedUser: _getConnectedUser,
};

export default LoginSwitcher;
